var stores = {
   "type": "FeatureCollection",
   "features": [
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.160325,32.744927 ]
    },
    "properties": {
    "name":"Concept 278",
    "copyPasteFromGoogle":"3687 Fifth Ave, San Diego, CA 92103",
    "address":"3687 Fifth Ave",
    "city":"San Diego",
    "stateAndZip":"CA 92103",
    "state":"CA",
    "postalCode":92103,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.179018,32.68654 ]
    },
    "properties": {
    "name":"High Tide Bottle Shop & Kitchen",
    "copyPasteFromGoogle":"933 Orange Avenue, Coronado, CA 92118 ",
    "address":"933 Orange Avenue",
    "city":"Coronado",
    "stateAndZip":"CA 92118 ",
    "state":"CA",
    "postalCode":92118,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.041037,32.724316 ]
    },
    "properties": {
    "name":"Keg N Bottle  -  Lemon Grove",
    "copyPasteFromGoogle":"1827 Massachusetts Ave, Lemon Grove, CA 91945",
    "address":"1827 Massachusetts Ave",
    "city":"Lemon Grove",
    "stateAndZip":"CA 91945",
    "state":"CA",
    "postalCode":91945,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.183101,32.8064622 ]
    },
    "properties": {
    "name":"Keg N Bottle - Clairemont",
    "copyPasteFromGoogle":"3566 Mt Acadia Blvd, San Diego, CA 92111",
    "address":"3566 Mt Acadia Blvd",
    "city":"San Diego",
    "stateAndZip":"CA 92111",
    "state":"CA",
    "postalCode":92111,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.0930454,32.6647255 ]
    },
    "properties": {
    "name":"Keg N Bottle - National City",
    "copyPasteFromGoogle":"2335 Highland Ave, National City, CA 91950",
    "address":"2335 Highland Ave",
    "city":"National City",
    "stateAndZip":"CA 91950",
    "state":"CA",
    "postalCode":91950,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.9604859,32.7480327 ]
    },
    "properties": {
    "name":"Keg N Bottle - Rancho San Diego",
    "copyPasteFromGoogle":"3681 Avocado Blvd, La Mesa, CA 91941",
    "address":"3681 Avocado Blvd",
    "city":"La Mesa",
    "stateAndZip":"CA 91941",
    "state":"CA",
    "postalCode":91941,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.060195,32.783925 ]
    },
    "properties": {
    "name":"KnB Bistro",
    "copyPasteFromGoogle":"6380 Del Cerro Blvd, San Diego, CA 92120",
    "address":"6380 Del Cerro Blvd",
    "city":"San Diego",
    "stateAndZip":"CA 92120",
    "state":"CA",
    "postalCode":92120,
    "phoneFormatted":"(619) 452-1777",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1184812,32.6960067 ]
    },
    "properties": {
    "name":"Liberty Call Distilling Co.",
    "copyPasteFromGoogle":"1985 National Ave Suite 1131, San Diego, CA 92113",
    "address":"1985 National Ave Suite 1131",
    "city":"San Diego",
    "stateAndZip":"CA 92113",
    "state":"CA",
    "postalCode":92113,
    "phoneFormatted":"(619) 432-1848",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1726955,32.6982846 ]
    },
    "properties": {
    "name":"Little Club",
    "copyPasteFromGoogle":"132 Orange Ave, Coronado, CA 92118",
    "address":"132 Orange Ave",
    "city":"Coronado",
    "stateAndZip":"CA 92118",
    "state":"CA",
    "postalCode":92118,
    "phoneFormatted":"(619) 435-5885",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.001918,32.7518076 ]
    },
    "properties": {
    "name":"Valley Farm Market",
    "copyPasteFromGoogle":"9040 Campo Rd, Spring Valley, CA 91977",
    "address":"9040 Campo Rd",
    "city":"Spring Valley",
    "stateAndZip":"CA 91977",
    "state":"CA",
    "postalCode":91977,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1681537,32.7497882 ]
    },
    "properties": {
    "name":"Vons Hillcrest",
    "copyPasteFromGoogle":"515 W Washington St, San Diego, CA 92103",
    "address":"515 W Washington St",
    "city":"San Diego",
    "stateAndZip":"CA 92103",
    "state":"CA",
    "postalCode":92103,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.114979,32.755537 ]
    },
    "properties": {
    "name":"JUNIOR MARKET",
    "copyPasteFromGoogle":"3638 El Cajon Blvd # D, San Diego, CA 92104",
    "address":"3638 El Cajon Blvd # D",
    "city":"San Diego",
    "stateAndZip":"CA 92104",
    "state":"CA",
    "postalCode":92104,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.102714,32.688062 ]
    },
    "properties": {
    "name":"MEX MART",
    "copyPasteFromGoogle":"1740 S 43rd St, San Diego, CA 92113",
    "address":"1740 S 43rd St",
    "city":"San Diego",
    "stateAndZip":"CA 92113",
    "state":"CA",
    "postalCode":92113,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1569448,32.8019752 ]
    },
    "properties": {
    "name":"KEARNY MESA MARKET",
    "copyPasteFromGoogle":"7631 Linda Vista Rd, San Diego, CA 92111",
    "address":"7631 Linda Vista Rd",
    "city":"San Diego",
    "stateAndZip":"CA 92111",
    "state":"CA",
    "postalCode":92111,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.095802,32.640085 ]
    },
    "properties": {
    "name":"BEST BEV",
    "copyPasteFromGoogle":"640 E St, Chula Vista, CA 91910",
    "address":"640 E St",
    "city":"Chula Vista",
    "stateAndZip":"CA 91910",
    "state":"CA",
    "postalCode":91910,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.247967,32.753175 ]
    },
    "properties": {
    "name":"PAT'S LIQUOR",
    "copyPasteFromGoogle":"5096 Voltaire St, San Diego, CA 92107",
    "address":"5096 Voltaire St",
    "city":"San Diego",
    "stateAndZip":"CA 92107",
    "state":"CA",
    "postalCode":92107,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.067692,32.761372 ]
    },
    "properties": {
    "name":"KEG&BOTTLE (EL-BLVD)",
    "copyPasteFromGoogle":"6060 El Cajon Blvd, San Diego, CA 92115",
    "address":"6060 El Cajon Blvd",
    "city":"San Diego",
    "stateAndZip":"CA 92115",
    "state":"CA",
    "postalCode":92115,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.0923054,32.6302464 ]
    },
    "properties": {
    "name":"KEG&BOTTLE LIQUOR(C.V)",
    "copyPasteFromGoogle":"677 H St, Chula Vista, CA 91910",
    "address":"677 H St",
    "city":"Chula Vista",
    "stateAndZip":"CA 91910",
    "state":"CA",
    "postalCode":91910,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.104276,32.735761 ]
    },
    "properties": {
    "name":"EL GENERAL MARKET #2",
    "copyPasteFromGoogle":"4232 Poplar St #4544, San Diego, CA 92105",
    "address":"4232 Poplar St #4544",
    "city":"San Diego",
    "stateAndZip":"CA 92105",
    "state":"CA",
    "postalCode":92105,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.927891,32.7774483 ]
    },
    "properties": {
    "name":"JAMACHA LIQUOR",
    "copyPasteFromGoogle":"1530 Jamacha Road, El Cajon, CA 92019",
    "address":"1530 Jamacha Road",
    "city":"El Cajon",
    "stateAndZip":"CA 92019",
    "state":"CA",
    "postalCode":92019,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.962677,32.788325 ]
    },
    "properties": {
    "name":"NICK'S LIQUOR",
    "copyPasteFromGoogle":"660 S Magnolia Ave, El Cajon, CA 92020",
    "address":"660 S Magnolia Ave",
    "city":"El Cajon",
    "stateAndZip":"CA 92020",
    "state":"CA",
    "postalCode":92020,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.161143,32.712858 ]
    },
    "properties": {
    "name":"QUICK STOP MARKET & WASH",
    "copyPasteFromGoogle":"728 Fourth Ave, San Diego, CA 92101",
    "address":"728 Fourth Ave",
    "city":"San Diego",
    "stateAndZip":"CA 92101",
    "state":"CA",
    "postalCode":92101,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.15931,32.713087 ]
    },
    "properties": {
    "name":"THE HUB MARKET",
    "copyPasteFromGoogle":"748 Sixth Ave, San Diego, CA 92101",
    "address":"748 Sixth Ave",
    "city":"San Diego",
    "stateAndZip":"CA 92101",
    "state":"CA",
    "postalCode":92101,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.0799182,32.7379973 ]
    },
    "properties": {
    "name":"TRADE WINDS LIQUOR",
    "copyPasteFromGoogle":"3111 54th St, San Diego, CA 92105",
    "address":"3111 54th St",
    "city":"San Diego",
    "stateAndZip":"CA 92105",
    "state":"CA",
    "postalCode":92105,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.115164,32.701442 ]
    },
    "properties": {
    "name":"MIKES MARKET(S.D)",
    "copyPasteFromGoogle":"3676 Ocean View Blvd, San Diego, CA 92113",
    "address":"3676 Ocean View Blvd",
    "city":"San Diego",
    "stateAndZip":"CA 92113",
    "state":"CA",
    "postalCode":92113,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.105392,32.576801 ]
    },
    "properties": {
    "name":"IB CHEVRON (13TH ST)",
    "copyPasteFromGoogle":"1300 Imperial Beach Blvd, Imperial Beach, CA 91932",
    "address":"1300 Imperial Beach Blvd",
    "city":"Imperial Beach",
    "stateAndZip":"CA 91932",
    "state":"CA",
    "postalCode":91932,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.996962,32.714748 ]
    },
    "properties": {
    "name":"HORSE SHOE LIQUOR",
    "copyPasteFromGoogle":"949 Grand Ave, Spring Valley, CA 91977",
    "address":"949 Grand Ave",
    "city":"Spring Valley",
    "stateAndZip":"CA 91977",
    "state":"CA",
    "postalCode":91977,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.961747,32.729019 ]
    },
    "properties": {
    "name":"JAMACHA PRODUCE MARKET",
    "copyPasteFromGoogle":"10761 Jamacha Blvd STE D, Spring Valley, CA 91978",
    "address":"10761 Jamacha Blvd STE D",
    "city":"Spring Valley",
    "stateAndZip":"CA 91978",
    "state":"CA",
    "postalCode":91978,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.9476614,32.855895 ]
    },
    "properties": {
    "name":"SANTEE DISCOUNT MARKET",
    "copyPasteFromGoogle":"11541 N Woodside Ave B, Santee, CA 92071",
    "address":"11541 N Woodside Ave B",
    "city":"Santee",
    "stateAndZip":"CA 92071",
    "state":"CA",
    "postalCode":92071,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.0641432,32.9516963 ]
    },
    "properties": {
    "name":"DISTILLERS OUTLET LIQUOR",
    "copyPasteFromGoogle":"12329 Poway Rd, Poway, CA 92064",
    "address":"12329 Poway Rd",
    "city":"Poway",
    "stateAndZip":"CA 92064",
    "state":"CA",
    "postalCode":92064,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1222455,32.8938706 ]
    },
    "properties": {
    "name":"QWIK KORNER(MIRAMAR RD)D",
    "copyPasteFromGoogle":"9532 Miramar Rd, San Diego, CA 92126",
    "address":"9532 Miramar Rd",
    "city":"San Diego",
    "stateAndZip":"CA 92126",
    "state":"CA",
    "postalCode":92126,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.123554,32.917251 ]
    },
    "properties": {
    "name":"JIMBO'S LIQUOR /SNK CORP",
    "copyPasteFromGoogle":"9388 Mira Mesa Blvd Pres, San Diego, CA 92126",
    "address":"9388 Mira Mesa Blvd Pres",
    "city":"San Diego",
    "stateAndZip":"CA 92126",
    "state":"CA",
    "postalCode":92126,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.112486,32.916345 ]
    },
    "properties": {
    "name":"SCRIPP'S RANCH LIQUOR",
    "copyPasteFromGoogle":"9969 Mira Mesa Blvd, San Diego, CA 92131",
    "address":"9969 Mira Mesa Blvd",
    "city":"San Diego",
    "stateAndZip":"CA 92131",
    "state":"CA",
    "postalCode":92131,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.092038,32.683513 ]
    },
    "properties": {
    "name":"RANCH HOUSE LIQUOR (PALM",
    "copyPasteFromGoogle":"404 Palm Ave, National City, CA 91950",
    "address":"404 Palm Ave",
    "city":"National City",
    "stateAndZip":"CA 91950",
    "state":"CA",
    "postalCode":91950,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.003499,32.746465 ]
    },
    "properties": {
    "name":"BANCROFT MARKET & LIQUOR",
    "copyPasteFromGoogle":"3650 Bancroft Dr, Spring Valley, CA 91977",
    "address":"3650 Bancroft Dr",
    "city":"Spring Valley",
    "stateAndZip":"CA 91977",
    "state":"CA",
    "postalCode":91977,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.971484,32.743712 ]
    },
    "properties": {
    "name":"SPRING LIQUOR",
    "copyPasteFromGoogle":"3509 Sweetwater Springs Blvd # 4, Spring Valley, CA 91978",
    "address":"3509 Sweetwater Springs Blvd # 4",
    "city":"Spring Valley",
    "stateAndZip":"CA 91978",
    "state":"CA",
    "postalCode":91978,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.8777925,32.7181033 ]
    },
    "properties": {
    "name":"JAMUL LIQUOR",
    "copyPasteFromGoogle":"13881 Campo Rd # C-9, Jamul, CA 91935",
    "address":"13881 Campo Rd # C-9",
    "city":"Jamul",
    "stateAndZip":"CA 91935",
    "state":"CA",
    "postalCode":91935,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.168507,32.878186 ]
    },
    "properties": {
    "name":"GALLERIA LIQUOR",
    "copyPasteFromGoogle":"6780 Miramar Rd Suite #107, San Diego, CA 92121",
    "address":"6780 Miramar Rd Suite #107",
    "city":"San Diego",
    "stateAndZip":"CA 92121",
    "state":"CA",
    "postalCode":92121,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1814198,32.74324 ]
    },
    "properties": {
    "name":"PALOMAR  MARKET",
    "copyPasteFromGoogle":"1802 W Washington St, San Diego, CA 92103",
    "address":"1802 W Washington St",
    "city":"San Diego",
    "stateAndZip":"CA 92103",
    "state":"CA",
    "postalCode":92103,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1707643,32.7496368 ]
    },
    "properties": {
    "name":"FALCON LIQUOR",
    "copyPasteFromGoogle":"805 W Washington St, San Diego, CA 92103",
    "address":"805 W Washington St",
    "city":"San Diego",
    "stateAndZip":"CA 92103",
    "state":"CA",
    "postalCode":92103,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.255716,32.798326 ]
    },
    "properties": {
    "name":"CRISCOLA'S LIQUOR STORE",
    "copyPasteFromGoogle":"4641 Mission Blvd, San Diego, CA 92109",
    "address":"4641 Mission Blvd",
    "city":"San Diego",
    "stateAndZip":"CA 92109",
    "state":"CA",
    "postalCode":92109,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1525476,32.7484171 ]
    },
    "properties": {
    "name":"SERVALL MARKET",
    "copyPasteFromGoogle":"1279 University Ave, San Diego, CA 92103",
    "address":"1279 University Ave",
    "city":"San Diego",
    "stateAndZip":"CA 92103",
    "state":"CA",
    "postalCode":92103,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.0647605,32.6758435 ]
    },
    "properties": {
    "name":"LA PALAPA #2 (SAN DIEGO)",
    "copyPasteFromGoogle":"2365 Reo Dr, San Diego, CA 92139",
    "address":"2365 Reo Dr",
    "city":"San Diego",
    "stateAndZip":"CA 92139",
    "state":"CA",
    "postalCode":92139,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.081292,32.7928066 ]
    },
    "properties": {
    "name":"FAST BREAK FOOD & MORE",
    "copyPasteFromGoogle":"5171 Zion Ave, San Diego, CA 92120",
    "address":"5171 Zion Ave",
    "city":"San Diego",
    "stateAndZip":"CA 92120",
    "state":"CA",
    "postalCode":92120,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.071383,33.094815 ]
    },
    "properties": {
    "name":"A1 LIQUOR",
    "copyPasteFromGoogle":"2250 S Escondido Blvd # 101, Escondido, CA 92025",
    "address":"2250 S Escondido Blvd # 101",
    "city":"Escondido",
    "stateAndZip":"CA 92025",
    "state":"CA",
    "postalCode":92025,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.930692,32.789092 ]
    },
    "properties": {
    "name":"COUNTRY WINE&SPIRIT#2 (MA",
    "copyPasteFromGoogle":"518 Jamacha Road, El Cajon, CA 92019",
    "address":"518 Jamacha Road",
    "city":"El Cajon",
    "stateAndZip":"CA 92019",
    "state":"CA",
    "postalCode":92019,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.076574,32.674977 ]
    },
    "properties": {
    "name":"THE LIQUOR BOTTLE",
    "copyPasteFromGoogle":"2605 E 18th St, National City, CA 91950",
    "address":"2605 E 18th St",
    "city":"National City",
    "stateAndZip":"CA 91950",
    "state":"CA",
    "postalCode":91950,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1173933,32.5839733 ]
    },
    "properties": {
    "name":"LEON DISCOUNT TOBACCO & F",
    "copyPasteFromGoogle":"185 Palm Ave, Imperial Beach, CA 91932",
    "address":"185 Palm Ave",
    "city":"Imperial Beach",
    "stateAndZip":"CA 91932",
    "state":"CA",
    "postalCode":91932,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.187437,33.134482 ]
    },
    "properties": {
    "name":"E FOOD STORE",
    "copyPasteFromGoogle":"997 W San Marcos Blvd, San Marcos, CA 92078",
    "address":"997 W San Marcos Blvd",
    "city":"San Marcos",
    "stateAndZip":"CA 92078",
    "state":"CA",
    "postalCode":92078,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.051316,32.603926 ]
    },
    "properties": {
    "name":"HILLTOP LIQUOR (PATRICK)",
    "copyPasteFromGoogle":"1419 Hilltop Dr # A, Chula Vista, CA 91911",
    "address":"1419 Hilltop Dr # A",
    "city":"Chula Vista",
    "stateAndZip":"CA 91911",
    "state":"CA",
    "postalCode":91911,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.117654,32.584847 ]
    },
    "properties": {
    "name":"MERCADO SHORES(NEW)",
    "copyPasteFromGoogle":"639 7th St, Imperial Beach, CA 91932",
    "address":"639 7th St",
    "city":"Imperial Beach",
    "stateAndZip":"CA 91932",
    "state":"CA",
    "postalCode":91932,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.3506958,33.1582299 ]
    },
    "properties": {
    "name":"BIG PIG LIQUOR",
    "copyPasteFromGoogle":"305 Carlsbad Village Dr, Carlsbad, CA 92008",
    "address":"305 Carlsbad Village Dr",
    "city":"Carlsbad",
    "stateAndZip":"CA 92008",
    "state":"CA",
    "postalCode":92008,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.990568,32.745681 ]
    },
    "properties": {
    "name":"KENWOOD LIQUOR (NEW)",
    "copyPasteFromGoogle":"9612 Dale Ave #1, Spring Valley, CA 91977",
    "address":"9612 Dale Ave #1",
    "city":"Spring Valley",
    "stateAndZip":"CA 91977",
    "state":"CA",
    "postalCode":91977,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.974487,32.66385 ]
    },
    "properties": {
    "name":"3N1 SPORTS BAR AND GRILL",
    "copyPasteFromGoogle":"2330 Proctor Valley Rd #101, Chula Vista, CA 91914",
    "address":"2330 Proctor Valley Rd #101",
    "city":"Chula Vista",
    "stateAndZip":"CA 91914",
    "state":"CA",
    "postalCode":91914,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.998132,32.802111 ]
    },
    "properties": {
    "name":"FLETCHER HILLS BOTTLE SHO",
    "copyPasteFromGoogle":"2447 Fletcher Pkwy, El Cajon, CA 92020",
    "address":"2447 Fletcher Pkwy",
    "city":"El Cajon",
    "stateAndZip":"CA 92020",
    "state":"CA",
    "postalCode":92020,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.071089,33.070449 ]
    },
    "properties": {
    "name":"LAGO COUNTRY WINE & SPIRIT",
    "copyPasteFromGoogle":"3440 Del Lago Blvd Suite: N, Escondido, CA 92029",
    "address":"3440 Del Lago Blvd Suite: N",
    "city":"Escondido",
    "stateAndZip":"CA 92029",
    "state":"CA",
    "postalCode":92029,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.1365272,32.748371 ]
    },
    "properties": {
    "name":"PARTY TIME LIQUOR # 12",
    "copyPasteFromGoogle":"2567 University Ave, San Diego, CA 92104",
    "address":"2567 University Ave",
    "city":"San Diego",
    "stateAndZip":"CA 92104",
    "state":"CA",
    "postalCode":92104,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.041818,32.742268 ]
    },
    "properties": {
    "name":"BIG K MARKET II",
    "copyPasteFromGoogle":"7245 Broadway, Lemon Grove, CA 91945",
    "address":"7245 Broadway",
    "city":"Lemon Grove",
    "stateAndZip":"CA 91945",
    "state":"CA",
    "postalCode":91945,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.071,33.136192 ]
    },
    "properties": {
    "name":"EL NORTE QWIK STOP",
    "copyPasteFromGoogle":"1140 E Mission Ave, Escondido, CA 92025",
    "address":"1140 E Mission Ave",
    "city":"Escondido",
    "stateAndZip":"CA 92025",
    "state":"CA",
    "postalCode":92025,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -117.24998,32.745817 ]
    },
    "properties": {
    "name":"NEWPORT QUIK STOP(MASAR)",
    "copyPasteFromGoogle":"4921 Newport Ave, San Diego, CA 92107",
    "address":"4921 Newport Ave",
    "city":"San Diego",
    "stateAndZip":"CA 92107",
    "state":"CA",
    "postalCode":92107,
    "phoneFormatted":"",
    "popUpContent":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ -116.930692,32.789092 ]
    },
    "properties": {
    "name":"REAL LIQUOR",
    "copyPasteFromGoogle":"518 Jamacha Road, El Cajon, CA 92019",
    "address":"518 Jamacha Road",
    "city":"El Cajon",
    "stateAndZip":"CA 92019",
    "state":"CA",
    "postalCode":92019,
    "phoneFormatted":"",
    "popUpContent":""
    }
  }
]
}

export default stores;