import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import styled from "styled-components"
import axios from "axios"
import turfDistance from "@turf/distance"
import { Link } from "gatsby"

import stores from "./pbchocolati.geojson"
import marker from "./marker.png"
import Logo from "../Logo"

// For some reason theme props aren't working on this page. Just use these for now.
const theme = {
  brown: function (opacity = 1) {
    return `rgba(28, 11, 6, ${opacity})`
  },
  orange: function (opacity = 1) {
    return `rgba(188, 103, 44, ${opacity})`
  },
  beige: function (opacity = 1) {
    return `rgba(203, 138, 94, ${opacity})`
  },
  black: function (opacity = 1) {
    return `rgba(32, 25, 27, ${opacity})`
  },
}

const Header = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    padding: 0 5px;
  }
  a {
    color: ${theme.brown(0.8)};
    text-decoration: none;
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 800;
    &:hover {
      color: ${theme.orange(0.8)};
    }
    transition: all 0.2s;
  }
`

const StyledPage = styled.div`
  height: 100vh;
  font-family: "Poppins", Geneva, Tahoma, sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0 0.8rem 0.8rem;
  @media (max-width: 600px) {
    height: auto;
    padding: 0;
  }
  .listingsAndMap {
    display: flex;
    height: calc(100% - 50px);
    @media (max-width: 600px) {
      flex-direction: column-reverse;
      height: auto;
      .listings {
        width: 100vw;
        .searchAndDistanceFilterContainer {
        }
      }
    }
  }
  .listings {
    width: 320px;
    p {
      margin: 0;
    }
    p.placeName {
      padding: 0 0.8rem 0.8rem 0.8rem;
      span {
        font-weight: 800;
      }
    }
    p.noResults {
      padding: 1.2rem;
      font-weight: 800;
      color: ${theme.brown(0.7)};
    }
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
    }
    ::-webkit-scrollbar-button {
      display: none;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.brown(0.7)};
      border-radius: 3px;
      max-height: 100px;
    }
    .listing {
      h3 {
        font-size: 14px;
        margin: 2px 0;
        color: ${theme.brown()};
        font-weight: 800;
      }
      cursor: pointer;
      margin: 0;
      padding: 0.5rem;
      border-style: solid;
      border-color: ${theme.beige()};
      border-width: 0 0 2px 0;
      .address {
        font-weight: 700;
        color: ${theme.brown(0.7)};
      }
      &:hover {
        background-color: ${theme.beige(0.4)};
      }
      transition: all 0.3s;
      border-left: solid 5px transparent;
    }
    .active {
      border-left: solid 5px ${theme.orange()};
      background-color: ${theme.beige(0.2)};
    }
  }
  .map {
    flex: 1;
    .marker {
      border: none;
      cursor: pointer;
      height: 40px;
      width: 40px;
      display: block;
      background-image: url(${marker});
      background-size: 100%;
      background-color: rgba(0, 0, 0, 0);
    }
    /* Marker tweaks */
    .mapboxgl-popup-close-button {
      color: white;
      top: -2rem;
      right: -2rem;
      font-size: 40px;
      border: none;
      outline: none;
      &:hover {
        background-color: none;
        transform: scale(1.1);
        color: ${theme.beige()};
      }
      transition: all 0.3s;
    }
    .mapboxgl-popup-content {
      font: 400 15px/22px "Poppins", Sans-serif;
      color: white;
      padding: 1rem;
      white-space: nowrap;
      width: min-content;
      background-color: ${theme.brown(0.9)};
      h3 {
        margin-bottom: 0.8rem;
      }
      h4 {
        margin-bottom: 1rem;
      }
      @media (max-width: 600px) {
        font: 400 12px/18px "Poppins", Sans-serif;
        width: 280px;
        white-space: pre-wrap;
      }
    }
    .mapboxgl-popup-content-wrapper {
      padding: 1rem;
    }
    .mapboxgl-popup-tip {
      border-top-color: ${theme.brown(0.9)};
    }
    .mapboxgl-control-container {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  div.mapboxgl-map {
    @media (max-width: 600px) {
      height: 73vh;
      width: 100vw;
      flex: none;
      .mapboxgl-canvas {
        height: 100%;
      }
    }
  }
`

const StyledGeocodeSearchContainer = styled.div`
  padding: 1rem;
  form {
    margin-bottom: 0;
  }
  input {
    width: 100%;
  }
  .buttons {
    padding: 0.3rem 0;
    display: flex;
    justify-content: space-between;
  }
  button {
    width: 24%;
    background-color: ${theme.brown(0.8)};
    color: white;
    border: none;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
    &:hover {
      border-color: ${theme.orange()};
      color: ${theme.orange()};
    }
    transition: all 0.3s;
  }
  button.selected {
    border-color: ${theme.orange()};
    color: ${theme.orange()};
  }
`

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYWRkaXNvbmlhbiIsImEiOiJja2l5czI4cXgyaWdoMzRwNHRkeDkybjQ5In0.AuxwRaGHw7uhQZZGZ-ILlw"

const Map = ({ initialStores }) => {
  const [state, setState] = useState({
    lng: 32.69970752374193,
    lat: -117.14519464634063,
    zoom: 12,
  })
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)
  const [stores, setStores] = useState()
  const [currentFeature, setCurrentFeature] = useState(null)
  const [popUp, setPopUp] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [singlePoint, setSinglePoint] = useState(null)
  const [geocodeInput, setGeocodeInput] = useState("")
  const [maxDistance, setMaxDistance] = useState(5)
  const [filteredStores, setFilteredStores] = useState()
  const [markers, setMarkers] = useState()

  useEffect(() => {
    if (initialStores) {
      const storesWithFeatureIDs = { ...initialStores }
      initialStores.features.forEach((store, i) => {
        store.properties.id = i
      })
      setStores(storesWithFeatureIDs)
      setFilteredStores(storesWithFeatureIDs)
    }
  }, [initialStores])

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/addisonian/ckja5zkjx3kie19ml20pn838j",
      center: [state.lat, state.lng],
      zoom: state.zoom,
    })
    map.addControl(new mapboxgl.NavigationControl(), "bottom-left")

    setMap(map)

    return () => map.remove()
  }, [state])

  useEffect(() => {
    if (map && filteredStores) {
      const markers = []
      map.on("load", function (e) {
        filteredStores.features.forEach(marker => {
          /* Create a div element for the marker. */
          var el = document.createElement("div")
          /* Assign a unique `id` to the marker. */
          el.id = "marker-" + marker.properties.id
          /* Assign the `marker` class to each marker for styling. */
          el.className = "marker"

          /**
           * Create a marker using the div element
           * defined above and add it to the map.
           **/
          const markerInstance = new mapboxgl.Marker(el, { offset: [0.5, 20] })
            .setLngLat(marker.geometry.coordinates)
            .addTo(map)

          /* Make markers clickable */
          el.addEventListener("click", function (e) {
            setCurrentFeature(marker)
          })
          markers.push(markerInstance)
        })
      })
      setMarkers(markers)
    }
  }, [map])

  // Clear all of the markers, and re-add the ones that are within the selected distance of the searched location
  useEffect(() => {
    if (map && filteredStores && markers) {
      markers.forEach(marker => marker.remove())
      filteredStores.features.forEach(marker => {
        /* Create a div element for the marker. */
        var el = document.createElement("div")
        /* Assign a unique `id` to the marker. */
        el.id = "marker-" + marker.properties.id
        /* Assign the `marker` class to each marker for styling. */
        el.className = "marker"

        /**
         * Create a marker using the div element
         * defined above and add it to the map.
         **/
        const markerInstance = new mapboxgl.Marker(el, { offset: [0.5, 20] })
          .setLngLat(marker.geometry.coordinates)
          .addTo(map)

        /* Make markers clickable */
        el.addEventListener("click", function (e) {
          setCurrentFeature(marker)
        })
        markers.push(markerInstance)
      })
      setMarkers(markers)
    }
  }, [filteredStores])

  const flyToStore = feature => {
    map.flyTo({
      center: feature.geometry.coordinates,
      zoom: 15,
    })
  }

  const createPopUp = feature => {
    if (popUp) popUp.remove()

    const newPopup = new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat(feature.geometry.coordinates)
      .setHTML(
        `<h3>${feature.properties.name}</h3><h4>${feature.properties.address}</h4>`
      )
      .addTo(map)
    setPopUp(newPopup)
  }

  useEffect(() => {
    if (currentFeature) {
      flyToStore(currentFeature)
      createPopUp(currentFeature)
    }
  }, [currentFeature])

  useEffect(() => {
    if (searchResults) {
      setSinglePoint(searchResults.features[0].center)
    }
  }, [searchResults])

  useEffect(() => {
    if (singlePoint) {
      var options = { units: "miles" }
      const storesClone = { ...stores }
      storesClone.features.forEach(function (store) {
        Object.defineProperty(store.properties, "distance", {
          value: turfDistance(singlePoint, store.geometry, options),
          writable: true,
          enumerable: true,
          configurable: true,
        })
      })
      storesClone.features.sort(function (a, b) {
        if (a.properties.distance > b.properties.distance) {
          return 1
        }
        if (a.properties.distance < b.properties.distance) {
          return -1
        }
        return 0 // a must be equal to b
      })
      // If the closest store is under the maxDistance, set it as the selected store (the map will move to it, and the popup will show)
      if (storesClone.features[0].properties.distance <= maxDistance) {
        setCurrentFeature(storesClone.features[0])
      }
      setStores(storesClone)
    }
  }, [singlePoint])

  // Filter stores by the selected max distance. Refilter anytime the submits a search (after all of the disances have been calculated and set), or the user changes the maxDistance
  useEffect(() => {
    if (singlePoint) {
      const storesClone = { ...stores }
      storesClone.features = storesClone.features.filter(store => {
        return store.properties.distance <= maxDistance
      })
      setFilteredStores(storesClone)
    }
  }, [stores, maxDistance])

  const handleSearch = async string => {
    const res = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${string}.json`,
      {
        params: {
          access_token: MAPBOX_TOKEN,
          country: ["US"],
          limit: 1,
        },
      }
    )
    if (res) setSearchResults(res.data)
  }

  return (
    <StyledPage>
      <Header>
        <Logo size="40px" />
        <Link to="/">Home</Link>
      </Header>
      <div className="listingsAndMap">
        <div className="listings">
          <GeocoderInput
            value={geocodeInput}
            onChange={text => setGeocodeInput(text)}
            onSubmit={handleSearch}
            maxDistance={maxDistance}
            onMaxDistanceChange={mi => setMaxDistance(mi)}
          />
          {searchResults && (
            <p className="placeName">
              Showing {filteredStores?.features.length} locations near: <br />
              <span>{searchResults.features[0].place_name}</span>
            </p>
          )}
          {filteredStores?.features.map(feature => {
            return (
              <Listing
                key={`listing-${feature.properties.name}`}
                {...feature.properties}
                onClick={() => setCurrentFeature(feature)}
                active={
                  currentFeature &&
                  feature.properties.id === currentFeature.properties.id
                }
              />
            )
          })}
          {filteredStores?.features.length === 0 && (
            <p className="noResults">
              Sorry, we can't find any locations within {maxDistance} miles.
              Please try a larger search radius, or a new search location.
            </p>
          )}
        </div>
        <div ref={mapContainerRef} className="map" />
      </div>
    </StyledPage>
  )
}

const MapWrapper = () => {
  if (typeof window !== `undefined`) {
    return <Map initialStores={stores} />
  } else return null
}

export default MapWrapper

const Listing = props => {
  return (
    <div
      className={`listing ${props.active && "active"}`}
      onClick={props.onClick}
    >
      <h3>{props.name}</h3>
      <p className="address">{props.address}</p>
      <p>
        {props.city}, {props.state} {props.postalCode}
      </p>
      {props.distance && <p>{props.distance.toFixed(2)} miles away</p>}
    </div>
  )
}

const GeocoderInput = props => {
  const handleSubmit = e => {
    e.preventDefault()
    props.onSubmit(props.value)
  }
  return (
    <StyledGeocodeSearchContainer>
      <form
        onSubmit={handleSubmit}
        className="searchAndDistanceFilterContainer"
      >
        <label>
          Search for PB Chocolati near you!
          <input
            type="text"
            className="search"
            value={props.value}
            placeholder=""
            onChange={e => props.onChange(e.target.value)}
          />
        </label>
        <div className="buttons">
          <label>
            5 mi
            <input
              type="radio"
              name="5 miles"
              value={5}
              checked={props.maxDistance === 5}
              onClick={() => props.onMaxDistanceChange(5)}
            />
          </label>
          <label>
            10 mi
            <input
              type="radio"
              name="10 miles"
              value={10}
              checked={props.maxDistance === 10}
              onClick={() => props.onMaxDistanceChange(10)}
            />
          </label>
          <label>
            25 mi
            <input
              type="radio"
              name="25 miles"
              value={25}
              checked={props.maxDistance === 25}
              onClick={() => props.onMaxDistanceChange(25)}
            />
          </label>
          <label>
            50 mi
            <input
              type="radio"
              name="50 miles"
              value={50}
              checked={props.maxDistance === 50}
              onClick={() => props.onMaxDistanceChange(50)}
            />
          </label>
        </div>
      </form>
    </StyledGeocodeSearchContainer>
  )
}
