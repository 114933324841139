import React from "react"
import Map from "../components/map/index"

const MapPage = () => {
  return (
    <>
      <Map />
    </>
  )
}

export default MapPage
